body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1ipsxd7-MuiDateCalendar-root {
  width: auto !important  ;
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  background-color:  #FBBA1E !important
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:active {
  background-color:  #FBBA1E !important
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus {
  background-color:  #FBBA1E !important
}

.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected , 
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus ,
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:active{
  background-color:#FBBA1E !important
}
.css-1ipsxd7-MuiDateCalendar-root .MuiPickersArrowSwitcher-button .css-1vooibu-MuiSvgIcon-root {
  color: #ffffff !important;
  font-size: 24px !important;
}