.App {
  background-color: "#f4f8f9"
 
 }
html{
  overflow: hidden !important;
}
body{
    font-family: sans-serif;
}
 ::-webkit-scrollbar {
  /* background-color: #e9c165; */
  width: 8px;
  height: 6px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background-color:#e9c165 ;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-datepicker-wrapper{
  width: 100% !important;
}
.react-datepicker-wrapper .MuiInputBase-root {
  flex-direction: row-reverse !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.stdropdown-input  input{
  border: none !important;
  background: none !important;
  padding: 14px !important  ;
  font-size: 15px;
}
.stdropdown-input  input:active ,.stdropdown-input  input:focus{
 outline: none !important;
}

.MuiPickersCalendarHeader-label{
  /* color: rgb(52 71 103) !important ; */
  color:  #FBBA1E !important ;
  font-weight: bold !important;
}
.MuiDayCalendar-weekDayLabel{
  color: #FBBA1E !important ;
  font-weight: bold !important;
}
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid rgb(52 71 103) !important;
}

.css-1ipsxd7-MuiDateCalendar-root {
  width: auto !important  ;
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  background-color:  #FBBA1E !important
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:active {
  background-color:  #FBBA1E !important
}
.css-n76w5a-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus {
  background-color:  #FBBA1E !important
}
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected , 
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus ,
.css-1qs9ekg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:active{ 
  background-color:#FBBA1E !important
}
.css-1ipsxd7-MuiDateCalendar-root .MuiPickersArrowSwitcher-button .css-1vooibu-MuiSvgIcon-root {
  color: #ffffff !important;
  font-size: 24px !important;
}

@media (min-width:600px){
  .imgLogo{
    display: none !important;
    
  }
}